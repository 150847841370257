exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-clinical-trial-expert-articles-index-js": () => import("./../../../src/pages/clinical-trial-expert-articles/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-expert-articles-index-js" */),
  "component---src-pages-clinical-trial-india-biologicals-blood-products-index-js": () => import("./../../../src/pages/clinical-trial-india/biologicals/blood-products/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-biologicals-blood-products-index-js" */),
  "component---src-pages-clinical-trial-india-biologicals-dna-products-index-js": () => import("./../../../src/pages/clinical-trial-india/biologicals/dna-products/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-biologicals-dna-products-index-js" */),
  "component---src-pages-clinical-trial-india-biologicals-index-js": () => import("./../../../src/pages/clinical-trial-india/biologicals/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-biologicals-index-js" */),
  "component---src-pages-clinical-trial-india-biologicals-stem-cells-products-index-js": () => import("./../../../src/pages/clinical-trial-india/biologicals/stem-cells-products/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-biologicals-stem-cells-products-index-js" */),
  "component---src-pages-clinical-trial-india-biologicals-vaccines-index-js": () => import("./../../../src/pages/clinical-trial-india/biologicals/vaccines/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-biologicals-vaccines-index-js" */),
  "component---src-pages-clinical-trial-india-consumer-claims-index-js": () => import("./../../../src/pages/clinical-trial-india/consumer-claims/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-consumer-claims-index-js" */),
  "component---src-pages-clinical-trial-india-dermatology-and-cosmetic-trials-hair-care-clinical-studies-index-js": () => import("./../../../src/pages/clinical-trial-india/dermatology-and-cosmetic-trials/hair-care-clinical-studies/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-dermatology-and-cosmetic-trials-hair-care-clinical-studies-index-js" */),
  "component---src-pages-clinical-trial-india-dermatology-and-cosmetic-trials-index-js": () => import("./../../../src/pages/clinical-trial-india/dermatology-and-cosmetic-trials/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-dermatology-and-cosmetic-trials-index-js" */),
  "component---src-pages-clinical-trial-india-dermatology-and-cosmetic-trials-skin-care-studies-index-js": () => import("./../../../src/pages/clinical-trial-india/dermatology-and-cosmetic-trials/skin-care-studies/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-dermatology-and-cosmetic-trials-skin-care-studies-index-js" */),
  "component---src-pages-clinical-trial-india-drug-fixed-dose-combinations-index-js": () => import("./../../../src/pages/clinical-trial-india/drug/fixed-dose-combinations/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-drug-fixed-dose-combinations-index-js" */),
  "component---src-pages-clinical-trial-india-drug-index-js": () => import("./../../../src/pages/clinical-trial-india/drug/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-drug-index-js" */),
  "component---src-pages-clinical-trial-india-drug-investigational-new-drugs-index-js": () => import("./../../../src/pages/clinical-trial-india/drug/investigational-new-drugs/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-drug-investigational-new-drugs-index-js" */),
  "component---src-pages-clinical-trial-india-drug-new-drugs-index-js": () => import("./../../../src/pages/clinical-trial-india/drug/new-drugs/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-drug-new-drugs-index-js" */),
  "component---src-pages-clinical-trial-india-drug-phase-3-index-js": () => import("./../../../src/pages/clinical-trial-india/drug/phase-3/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-drug-phase-3-index-js" */),
  "component---src-pages-clinical-trial-india-drug-subsequent-new-drugs-index-js": () => import("./../../../src/pages/clinical-trial-india/drug/subsequent-new-drugs/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-drug-subsequent-new-drugs-index-js" */),
  "component---src-pages-clinical-trial-india-index-js": () => import("./../../../src/pages/clinical-trial-india/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-index-js" */),
  "component---src-pages-clinical-trial-india-medical-device-ivd-clinical-investigation-index-js": () => import("./../../../src/pages/clinical-trial-india/medical-device-ivd/clinical-investigation/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-medical-device-ivd-clinical-investigation-index-js" */),
  "component---src-pages-clinical-trial-india-medical-device-ivd-clinical-performance-evaluation-index-js": () => import("./../../../src/pages/clinical-trial-india/medical-device-ivd/clinical-performance-evaluation/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-medical-device-ivd-clinical-performance-evaluation-index-js" */),
  "component---src-pages-clinical-trial-india-medical-device-ivd-index-js": () => import("./../../../src/pages/clinical-trial-india/medical-device-ivd/index.js" /* webpackChunkName: "component---src-pages-clinical-trial-india-medical-device-ivd-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cro-clinical-trial-management-clinical-project-management-index-js": () => import("./../../../src/pages/cro-clinical-trial-management/clinical-project-management/index.js" /* webpackChunkName: "component---src-pages-cro-clinical-trial-management-clinical-project-management-index-js" */),
  "component---src-pages-cro-clinical-trial-management-clinical-site-management-index-js": () => import("./../../../src/pages/cro-clinical-trial-management/clinical-site-management/index.js" /* webpackChunkName: "component---src-pages-cro-clinical-trial-management-clinical-site-management-index-js" */),
  "component---src-pages-cro-clinical-trial-management-clinical-trial-registry-india-index-js": () => import("./../../../src/pages/cro-clinical-trial-management/clinical-trial-registry-india/index.js" /* webpackChunkName: "component---src-pages-cro-clinical-trial-management-clinical-trial-registry-india-index-js" */),
  "component---src-pages-cro-clinical-trial-management-ethics-commitee-registration-index-js": () => import("./../../../src/pages/cro-clinical-trial-management/ethics-commitee-registration/index.js" /* webpackChunkName: "component---src-pages-cro-clinical-trial-management-ethics-commitee-registration-index-js" */),
  "component---src-pages-cro-clinical-trial-management-index-js": () => import("./../../../src/pages/cro-clinical-trial-management/index.js" /* webpackChunkName: "component---src-pages-cro-clinical-trial-management-index-js" */),
  "component---src-pages-feed-js": () => import("./../../../src/pages/feed.js" /* webpackChunkName: "component---src-pages-feed-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-key-opinion-leaders-js": () => import("./../../../src/pages/key-opinion-leaders.js" /* webpackChunkName: "component---src-pages-key-opinion-leaders-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-taxonomy-js": () => import("./../../../src/templates/taxonomy.js" /* webpackChunkName: "component---src-templates-taxonomy-js" */)
}

